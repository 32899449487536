:root {
  --cl-danger: rgb(201, 74, 74);
  --text-main: rgba(41, 41, 41, 1);
  --text-secondary: rgba(117, 117, 117, 1);
  --stroke: #d9d9d9;
}

.user-panel {
  display: flex;
  align-items: center;
}

.icheck>label {
  padding-left: 20px;
}

.btn-default {
  background-color: #fff;
  color: var(--text-secondary);
  border-color: #ddd;
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
  background-color: s;
  outline: none !important;
}

.max-banner {
  padding: 3rem 3rem 1rem;
  width: 100%;
  max-height: 560px;
}

.max-banner img {
  width: 10%;
  height: 100%;
  object-fit: contain;
}

.spx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fx {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.btn-edit {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pos30 {
  left: -30px;
}

.pos30 li a {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sub.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}

.sub.content-header h4 {
  font-size: 1.4rem;
}

.user-panel>.image>img {
  width: 45px;
  height: 45px;
  transition: width, height .2s ease-out;
  object-fit: cover;
}

.user-panel>.info>p {
  margin: 0;
}

.nav-tabs-custom>.tab-content {
  padding: 1rem;
  padding-bottom: 4rem;
  background: transparent;
}

.row.greybg .box-card {
  background-color: #f3f3f3;
}

.sidebar-mini.sidebar-collapse .user-panel>.image>img {
  width: 25px;
  height: 25px;
}

.navbar-nav>.user-menu .user-image {
  object-fit: cover;
}

.navbar-nav>.user-menu>.dropdown-menu>li.user-header>img {
  object-fit: cover;
}

.small-box .icon {
  font-size: 70px;
}

/* error component */
.error__wrapper {
  font-size: 1.2rem;
  color: var(--cl-danger)
}

.error__text {
  color: var(--cl-danger);
  font-size: 1.2rem;
}

.error {
  color: var(--cl-danger);
}

.error__count {
  gap: 1px;
}

.error__count .count.active {
  color: var(--cl-danger);
}

/* .page-wrapper {
  padding: 0 4rem;
} */

/* trucate */
.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--text-secondary);
}

.box-link {
  color: var(--text-main);
}

.cl1 {
  -webkit-line-clamp: 1;
  height: 2rem;
}

.cl3 {
  -webkit-line-clamp: 3;
}

.user-btn {
  background-color: #222d32;
  outline: none;
  border: none;
}

.user-btn:active,
.user-btn:hover,
.user-btn:focus,
.user-btn:focus-within {
  background-color: #222d32 !important;
  outline: none !important;
}

/* table */
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 10px 1rem;
}

.user-table {
  width: 100%;
  margin-top: 4rem;
}

.user-wrapper {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.user-wrapper .btn-default {
  background-color: transparent;
  border: none;
}

.user-wrapper .user-img {
  width: 30px;
  height: 30px;
}

.user-wrapper .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-wrapper .user-desc {
  display: flex;
  flex-direction: column;
}

.user-wrapper .user-desc p {
  margin: 0;
}

.user-desc .name {
  color: var(--text-main);
}

.user-desc .uname {
  color: var(--text-secondary);
}

/* box card*/
.box-template {
  overflow: auto;
}

.box-card {
  width: 100%;
  position: relative;
  margin-top: 3rem;
  background-color: white;
}

.box-content {
  display: flex;
  flex-direction: column;
}

.box-banner {
  height: 20rem;
  width: 100;
}

.box-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.box-desc {
  padding: 15px;
}

.box-desc .box-title h5 {
  margin-top: 0;
  font-size: 1.8rem;
  color: var(--text-main);
}

.box-desc .box-text {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.box-action {
  border-top: .5px solid var(--stroke);
  padding: 10px;
}

.box-status {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.box-links a {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: var(--text-secondary);
  font-size: 1.6rem;
  font-weight: 400;
}

/* post */
.padSide {
  padding: 0 3rem;
}

.post-title h5 {
  font-size: 2rem;
  color: var(--text-main);
}

.post-title.sub h5 {
  font-size: 1.6rem;
  color: var(--text-secondary);
}

.post-content {
  width: 100%;
  padding: 0 3rem;
}

.post-content blockquote {
  font-size: 1.1rem;
  width: 100%;
  margin: 20px auto;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid var(--text-secondary);
  line-height: 1.6;
  position: relative;
  background: rgba(237, 237, 237, 1);
}

.post-content blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: var(--text-secondary);
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

.post-content blockquote::after {
  content: '';
}

.post-content p {
  padding: 0px !important;
  hyphens: auto;
  word-break: break-word;
  margin-top: 25px;
  font-size: 1.5075rem;
  letter-spacing: 0.01em;
  line-height: 1.6;
  color: var(--text-main)
}

.post-content.skill p {
  margin-top: 10px;
}

/* btn-action */
.btn-action {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-main)
}

/* slider container */

.slider__container {
  width: 100%;
  height: auto;
  position: relative;
}

.sli__der {
  display: none;
  height: auto;
}

.sli__der img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.sli__der__prev,
.sli__der__next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border: none;
  border-radius: 15px;
  user-select: none;
}

.sli__der__prev:hover,
.sli__der__prev:focus,
.sli__der__prev:active,
.sli__der__next:hover,
.sli__der__next:focus,
.sli__der__next:active {
  background-color: #fff;
}

.sli__der__next {
  right: 15px;
  border-radius: 15px;
}

.sli__der__prev {
  left: 15px;
  border-radius: 15px;
}

.sli__der__prev:hover,
.sli__der__next:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.fade__efct {
  animation-name: fade;
  animation-duration: 1.5s;
}

/* create form */
.create-form {
  margin-top: 3rem;
}

/* toolbar */
#toolbar-controls {
  background-color: #e0e0e0;
  padding: 3px 5px;
  z-index: 500;
}

#toolbar-controls button {
  background-color: transparent;
  font-size: 1.2rem;
  padding: 6px 10px;
  color: var(--text-main);
  border: none;
  width: fit-content;
  margin-right: 3px;
}

#toolbar-controls button:last-child {
  margin-right: 0px;
}

#toolbar-controls button.active {
  color: orangered;
}

/* editor */
#editor-container {
  width: 100%;
  font-size: 1.5rem;
  /* margin-top: 50px; */
  position: relative;
  padding-bottom: 5rem;
  min-height: 229px;
}

.editor-ul {
  margin-top: 25px;
  padding-left: 2rem;
}

.editor-ul li {
  letter-spacing: 0.01em;
  line-height: 1.6;
  font-size: 1.505rem;
}

#editor-container .ql-editor {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 10px !important;
  border-radius: 10px;
  color: rgb(26, 26, 26);
}

.ql-editor.ql-blank::before {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-placeholder);
  font-size: 1.5rem;
  font-weight: 200;
}

.ql-editor .ql-clipboard {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  padding: 0 10px !important;
  border-radius: 10px;
}

#editor-container .ql-editor:focus-visible {
  outline: transparent;
}

.ql-clipboard:focus-within {
  outline: transparent;
}

.ql-editor p {
  margin-top: 25px;
  font-size: 1.5875rem;
  letter-spacing: 0.01em;
  line-height: 1.6;
}

.ql-editor h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

.ql-editor h2,
.ql-editor h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

.ql-editor p:first-child {
  margin-top: 0;
}

.ql-editor p:last-child {
  padding-bottom: .5rem;
}

#editor-container hr {
  border: none;
  color: #111;
  letter-spacing: 1em;
  text-align: center;
}

#editor-container a {
  text-decoration: underline;
}

#editor-container blockquote {
  font-size: 1.1rem;
  width: 100%;
  margin: 20px auto;
  font-style: italic;
  color: #555555;
  padding: 1.2em 30px 1.2em 75px;
  border-left: 8px solid var(--text-main-opacity);
  line-height: 1.6;
  position: relative;
  background: var(--cl-ed-box);
}

#editor-container blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: var(--text-main-opacity);
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

#editor-container blockquote::after {
  content: '';
}

.ql-container>.ql-editor.ql-blank::before {
  font-style: normal;
  color: var(--text-secondary);
  padding: 0px !important;
  left: 9px !important;

}

.input-group.exp a {
  padding: .5rem;
  width: 100px;
  background: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* info box */
.info-box.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-box.flex .info-box-content {
  margin: 0;
}

.info-box.flex .info-action {
  cursor: pointer;
  min-width: 50px;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-stacked button {
  width: 100%;
}

/* raritng component */
.custom-rating-wrapper {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.navbar-nav>.notifications-menu>.dropdown-menu>li .menu>li>p {
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  cursor: pointer;
  margin: 0;
}

.custom-rating-mask {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.custom-rating-img,
.custom-rating-mask::before {
  width: 100%;
  height: 100%;
  background-image: url('../assets/imgs/star.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-rating-img {
  filter: grayscale(100%);
}

.custom-rating-mask::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  clip-path: circle(0px at left center);
  transition: all .3s;
  z-index: 1;
}

.custom-rating-mask[data-star="1"]::before {
  clip-path: circle(20px at left center);
}

.custom-rating-mask[data-star="0.1"]::before {
  clip-path: circle(2px at left center);
}

.custom-rating-mask[data-star="0.2"]::before {
  clip-path: circle(4px at left center);
}

.custom-rating-mask[data-star="0.3"]::before {
  clip-path: circle(6px at left center);
}

.custom-rating-mask[data-star="0.4"]::before {
  clip-path: circle(8px at left center);
}

.custom-rating-mask[data-star="0.5"]::before {
  clip-path: circle(10px at left center);
}

.custom-rating-mask[data-star="0.6"]::before {
  clip-path: circle(12px at left center);
}

.custom-rating-mask[data-star="0.7"]::before {
  clip-path: circle(14px at left center);
}

.custom-rating-mask[data-star="0.8"]::before {
  clip-path: circle(16px at left center);
}

.custom-rating-mask[data-star="0.9"]::before {
  clip-path: circle(18px at left center);
}

.rating__comp {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.rating__desc {
  display: flex;
  align-items: center;
  gap: .5rem;
}


/* .rating__comp .rating__star .checked {
  color: orange;
}

.rating__comp .rating__star .unchecked {
  color: #74767e;
} */

.rating__desc .rating__count {
  color: orange !important;
}

.error-page{
  display: flex;
  align-items: center;
}

.error-page>.error-content{
  margin-left: 50px;
}


.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cl-primary);
  opacity: .8;
  z-index: 999;
}

.loader-wrapper.cover {
  background-color: white;
  opacity: 1;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid grey;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.loader-wrapper.cover .loader {
  width: 50px;
  height: 50px;
}